.App-logo {
  width: 150px;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain aspect ratio */
  margin: auto;
}


.error-message {
  color: red;
  font-size: 12px;
}

.react-confirm-alert-body h1 {
  font-size: 18px;
}

.react-confirm-alert-button-group {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center !important;
  margin-top: 20px;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 350px !important;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
  text-align: center !important;
}

.react-confirm-alert-button-group>button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 8px 20px !important;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 14px !important;
  cursor: pointer;
}

fieldset {
  padding: 2px 15px 2px 15px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin-bottom: 10px;
}

legend {
  font-size: 16px;
  padding: 5px;
}

.text-bold {
  font-weight: bold
}

.view-label {
  font-size: 12px;
  color: #838694;
  padding-bottom: 12px;
  border-bottom: 1px solid #d3d3d3;
  padding-left: 0px;
  margin-top: -5px;
  display: flex;
  justify-content: space-between;
  overflow-wrap: anywhere;
}


.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.man-start {
  display: inline-block;
  color: red;
  margin-left: 3px;
}

.view-mode span.man-start {

  display: none;
}

.add-mode span.man-start {
  display: inline-block;
  color: red;
  margin-left: 3px;
}

.btn-circle-v1 {
  border-radius: 50%;
  padding: 0;
  width: 1.7rem;
  height: 1.7rem;
  line-height: 24px;
}

.btn-circle-v2 {
  border-radius: 50%;
  padding: 0;
  width: 1.4rem;
  height: 1.4rem;
  line-height: 18px;
  float: right;
}

.text-danger {
  color: '#F80800',
}

.text-success {
  color: '#58ae25',
}

.profile-card {
  align-items: center;
  margin-bottom: 15px;
  background-color: #fff;
  padding: 10px;
  margin-left: 0;

}

/* drop zone */

.drop_area {
  width: 100%;
  height: 190px;
  border-radius: 5px;
  border: 2px dashed #a59bff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file_list_container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #d3d3d3;
  padding: 5px;
  border-radius: 5px;
  justify-content: space-between;
}

.file_list_container img {
  width: 100px;
  height: auto;
}

.modal-body ul {
  padding: 0px;
}

ul li .file_list_container img {
  max-width: 60px;
  max-height: 60px;
  width: auto;
  height: auto;
  display: block;
  object-fit: contain;
}

.drop_image_container {
  height: 60px;
  width: 60px;
  border-radius: 5px;
  margin-right: 11px;
  display: flex;
}

.drop_file_name {
  font-size: 12px;
}

.remove-drop {
  color: red;
  cursor: pointer;
}

.pointer {
  color: #68addf;
  cursor: pointer !important;
}

/* Default styles */
.img-container {
  /* max-width: 420px; */
  height: auto;
  display: block;
}


/* Media query for smaller screens */
/* @media screen and (max-width: 600px) {
  .img-container {
    max-width: 200px;
  }
} */

@media screen and (max-width: 1200px) {
  .table-header-total-object {
    display: flex;
    flex-direction: column !important;
  }
}

.img-container {
  display: flex;
  justify-content: center;
  /* Horizontally center the image */
  align-items: center;
  /* Vertically center the image */
}

.centered-image {
  max-width: 100%;
  /* Ensure the image does not exceed its container */
  max-height: 100%;
  /* Ensure the image does not exceed its container */
}

.img-container {
  position: relative;
}

.delete-button {
  position: absolute;
  top: 5px;
  /* Adjust this value to properly position the button */
  right: 5px;
  /* Adjust this value to properly position the button */
  background-color: rgb(221, 74, 87);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  /* Adjust button size as needed */
  height: 30px;
  /* Adjust button size as needed */
  line-height: 30px;
  /* Adjust button size as needed */
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: '19px';
  margin: '0px 5px';
  cursor: 'pointer';
}

.centered-image {
  display: block;
  max-width: 100%;
  height: auto;
}

.doc-image-preview {
  max-width: 60px;
  max-height: 60px;
}

.doc-image-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.image-preview-holder {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.file_versions {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.file-version-info {
  font-size: 12px;
  color: #646464;
}

.file-version-name {
  font-size: 14px;
  color: #333333;
}

/* Add cursor pointer to table headers */
th {
  cursor: pointer;
}

.tab-active {
  background-color: #2f5fb8;
  color: #ffffff;
  border-radius: unset;
  margin-bottom: -10px;
  /* border-bottom: 4px solid rgb(0, 26, 255); */
}

#dropdown {
  width: 66%;
}

.tab-active:hover {
  background-color: #2f5fb8;
  color: #ffffff;
}

.tab-btn:hover {
  background-color: #2f5fb8;
  color: #ffffff;
}

.tab-btn {
  background-color: #ffffff;
  border-radius: unset;
  margin-bottom: -10px;
}

.table-scroll {
  max-width: 100%;
  overflow-x: auto;
}

/* .text-box {
  inline-size: 450px;
  overflow-wrap: break-word; 
}

@media (max-width: 600px) {
  .text-box {
    inline-size: 230px; 
    overflow-wrap: break-word;
  }
} */

.profile-image {
  width: 80px;
  height: 80px;
}

/* .checkbox-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.checkbox-label {
  margin-left: 5px;
} */

.table-disable-checkbox {
  opacity: 0.5;
  pointer-events: none;
}

;

/* .text-box2 {
  overflow-wrap: break-word;
  max-block-size: fit-content;
  max-width: 802px; 
}

@media screen and (max-width: 600px) {
  .text-box2 {
      max-width: 100%;
  }
} */

@media screen and (max-width: 600px) {
  .row .col-2 .responsive_view {
    width: 100px;
  }
}


@media (max-width: 768px) {
  .responsive_view {
    width: 30px;
    /* Adjust this width as needed for mobile */
  }

  .responsive_view2 {
    width: 140px;
    /* Adjust this width as needed for mobile */
  }

  .responsive_header {
    width: 160px;
    flex-direction: row;
  }

  .col-10.responsive_view {
    padding-top: 50px;
  }
}



.responsive_header {
  display: 'flex';
  flex-direction: row-reverse;
  font-size: 15px !important;
}

/* New view ui style */
.lenged-view {
  margin-left: 4px;
  color: #023e75;
  font-family: Arial, sans-serif;
  /* margin-bottom: 15px; */
}
/* Add this CSS to your stylesheet */
.section-spacing {
  margin-bottom: 15px; /* Adjust this value as needed */
}

.inv-lable {
  font-weight: bold;
  color: #626262;
}

.inv-value {
  padding: 2px;
  font-size: 13px;
}

.ml-negative-2 {
  margin-left: -2px;
}

.bg-white {
  background: white;
  ;
}

.additional-date {
  margin-left: 10px;
  color: gray;
  float: right;
}

.mr-7 {
  margin-right: 7px;
}

.tooltip-custom:hover {
  background: blue;
  font-size: 40px;
}


.css-1nmdiq5-menu {
  z-index: 999999 !important;
}

.notifications-box .dropdown-item div {
  width: auto !important;
  flex: auto !important;
  max-width: 100% !important;
}

.only-visible-in-print {
  display: none;
}

@media print {
  .no-print {
    display: none !important;
  }

  .table td {
    font-size: 9px !important;
  }

  .stock-list-header-style {
    font-size: 10px !important;
  }

  .inv-value {
    padding: 2px;
    font-size: 9px !important;
  }
  .print-table thead th {
    background-color: var(--bs-primary);
    font-weight: bold; 
  }
  .print-font-header {
    font-size: 10px !important;
  }

  #inv-value {
    font-size: 9px !important;
  }

  #print-font-header {
    font-size: 10px !important;
    font-weight: bold !important;
  }

  legend {
    font-size: 11px !important;
  }

  .heading-print {
    flex: 'auto';
    text-align: center;
    margin-top: 2px;
    font-size: 12px !important;
    color: #626262;
    /* border-bottom: 1px solid ; */
  }

  .only-visible-in-print {
    display: block;
  }
  .total-row {
    background-color: transparent !important;
    font-weight: bolder;
    background: transparent !important;
  }
  
  .total-row td {
    border-top: 1px solid #000;
    /* border-right: none;
    border-bottom: none;
    border-left: none; */
  }

  .total-row .table-row-total-empty{
    border-top: 1px solid #000;
    /* background-color: yellow !important; */
  }

  .total-row .table-row-total {
    /* background: red !important; */
    border-left: #000 !important;
    border-right: #000 !important;
    border-bottom: #000 !important
  }
  .odd {
    background-color: white; /* White background for odd rows */
}

.even {
    /* background-color: #dee2e6; */
    background-color: #f2f2f2;
}

};

#net-worth-title {
  font-size: 30px !important;
  font-weight: bold !important;
  color: #636161 !important;
}

.justify-end {
  justify-content: end;
}

/* Popup Design */
.confirm-button {
  padding: 10px 20px;
  margin: 5px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.confirm-yes {
  background-color: #4CAF50; /* Green */
  color: white;
}

.confirm-yes:hover {
  background-color: #45a049;
}

.confirm-backup {
  background-color: #FF9800; /* Orange */
  color: white;
}

.confirm-backup:hover {
  background-color: #e68a00;
}

.confirm-no {
  background-color: #f44336; /* Red */
  color: white;
}

.confirm-no:hover {
  background-color: #d32f2f;
}

/* Overlay Background */
.custom-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Popup Container */
.custom-popup {
  background: #fff;
  width: 500px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.custom-popup h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.custom-popup p {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Button Group for Alignment */
.button-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Buttons Styling */
.confirm-button {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap; /* Prevent text wrapping */
}

.confirm-yes {
  background-color: #4caf50; /* Green */
  color: white;
}

.confirm-yes:hover {
  background-color: #45a049;
}

.confirm-backup {
  background-color: #ff9800; /* Orange */
  color: white;
}

.confirm-backup:hover {
  background-color: #e68a00;
}

.confirm-no {
  background-color: #f44336; /* Red */
  color: white;
}

.confirm-no:hover {
  background-color: #d32f2f;
}

.user-status-button {
  background-color: #d3d3d3;
  color: #333;
  padding: 1px 10px !important;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 13px;
}

.user-status-button:hover{
  background-color: #c0c0c0;
}

.user-impersonation-button{
  color: #11caf0;
  margin-right: 1vw;
  margin-top: 0.5vw;
}

.user-impersonation-button:hover{
  cursor: pointer;
}